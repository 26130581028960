<template>
  <div class="card card-flush pt-5" v-if="!uploaded">
    <div class="card-header">
      <div class="card-title">
        <h1 class="card-label">Import from Excel Tutorial</h1>
      </div>
      <div class="card-subtitle d-flex align-items-center">
        <div>
          <input
            @change="uploadExcel($event)"
            class="d-none"
            id="file"
            type="file"
            accept=".xlsx"
          />
          <label
            class="btn btn-success d-flex align-items-center mt-3 mt-lg-0"
            for="file"
            ><span class="material-icons-outlined me-3"> folder </span> Browse
            Excel File</label
          >
        </div>
      </div>
    </div>
    <div class="card-body">
      <img
        class="card-img"
        src="/media/images/ImportCarTutorial.png"
        alt="Car Tutorial"
      />
      <p class="pt-8 fs-2">
        วิธีเพื่มรถจาก Excel:
        <span class="text-dark">
          ใส่ข้อมูลใน Excel ให้ตรงกับ Column ตามรูปภาพ
          ข้อมูลในแต่ล่ะแถวจะมีความเกี่ยวข้องกัน โดยมีความใหญ่จากซ้ายไปขวา
        </span>
      </p>
    </div>
  </div>

  <div class="card card-flush pt-5" v-else>
    <div class="card-header">
      <div class="card-title">
        <h1 class="card-label">Imported Car Lists (Preview)</h1>
      </div>
      <div class="card-subtitle d-flex align-items-center">
        <div class="pt-lg-0 pt-4">
          <button
            @click="cancelUpload()"
            name="cancel import"
            class="btn btn-danger me-7"
          >
            Cancel
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#confirm"
            name="confirm import"
            class="btn btn-primary"
            :data-kt-indicator="loading ? 'on' : 'off'"
            :disabled="loading"
          >
            Import
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <Datatable
        :loading="loading"
        :tableData="tableData"
        :tableHeader="tableHeader"
      >
        <template v-slot:cell-no="{ row }">
          {{ row.no }}
        </template>
        <template
          :key="i"
          v-for="(item, i) in tableHeader.filter((data) => data.key !== 'no')"
          v-slot:[`cell-${item.key}`]="{ row }"
        >
          <div>
            {{ row[item.key] }}
          </div>
        </template>
      </Datatable>
    </div>
  </div>

  <ConfirmModal ref="modalRef">
    <button
      @click="uploadTable()"
      type="button"
      name="confirm"
      class="btn btn-primary px-10"
    >
      Confirm
    </button>
  </ConfirmModal>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import readXlsxFile from 'read-excel-file';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { hideModal } from '@/core/helpers/dom';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
// import { refreshTokens } from '@/core/services/JwtService';

export default {
  name: 'ImportCar',
  components: {
    Datatable,
    ConfirmModal,
  },
  setup() {
    const router = useRouter();
    const uploaded = ref(false);
    const loading = ref(false);
    const modalRef = ref(null);
    const tableData = ref([]);
    const tableHeader = [
      {
        name: 'Type',
        key: 'type',
        sortable: false,
      },
      {
        name: 'Tag',
        key: 'tag',
        sortable: false,
      },
      {
        name: 'Brand',
        key: 'brand',
        sortable: false,
      },
      {
        name: 'Model',
        key: 'model',
        sortable: false,
      },
      {
        name: 'Sub Model',
        key: 'serie',
        sortable: false,
      },
      {
        name: 'Model Year',
        key: 'year',
        sortable: false,
      },
      {
        name: 'Seat',
        key: 'seat',
        sortable: false,
      },
      {
        name: 'Doors',
        key: 'door',
        sortable: false,
      },
      {
        name: 'Transmission',
        key: 'transmission',
        sortable: false,
      },
    ];

    onMounted(() => {
      setCurrentPageBreadcrumbs('Import From Excel', ['Import Car'], {}, false);
    });

    const uploadExcel = async (e) => {
      const file = e.target.files[0];
      if (file.name.split('.')[file.name.split('.').length - 1] !== 'xlsx') {
        alert('Please select a valid Excel file');
        return;
      }
      uploaded.value = true;
      loading.value = true;

      const parsedExcel = await readXlsxFile(file);

      tableData.value = parsedExcel.map((spec) => {
        const [type, tag, brand, model, serie, year, seat, door, transmission] =
          spec;
        return {
          type,
          tag,
          brand,
          model,
          serie,
          year,
          seat,
          door,
          transmission,
        };
      });

      loading.value = false;
    };

    const cancelUpload = () => {
      uploaded.value = false;
      tableData.value = [];
    };

    const uploadTable = async () => {
      hideModal(modalRef.value.$el);
      loading.value = true;

      try {
        console.log(tableData.value);
        // await refreshTokens();
        await ApiService.post('car/import', {
          dataImport: [...tableData.value],
        });

        loading.value = false;

        await Swal.fire({
          text: 'Cars have been successfully imported.',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Go to car lists',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        });

        router.push({ name: 'Car Lists' });
      } catch (err) {
        const message =
          err.response.data.message ||
          'Some of the types are not valid. Please check the table.';

        await Swal.fire({
          icon: 'error',
          buttonsStyling: false,
          text: message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
        loading.value = false;
      }
    };

    return {
      uploaded,
      loading,
      tableData,
      tableHeader,
      uploadExcel,
      cancelUpload,
      uploadTable,
      modalRef,
    };
  },
};
</script>

<style lang="sass" scoped>
p
  color: #1492E6
</style>
